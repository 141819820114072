// Migrated
<template lang="pug">
footer.footer.container-fluid
  .row.h-100
    .col-xl-9.col-12.mx-auto.h-100
      .row.h-100
        .col-xl-8.col-lg-7.col-md-7.row
          .footer-menu.col-12.col-lg-6(
            v-for="(column, columnIndex) in footer",
            :key="columnIndex"
          )
            .footer-heading.mb-3 {{ column.label }}
            ul
              li(
                v-for="(columnItem, columnItemIndex) in column.links",
                :key="columnItemIndex"
              )
                a(
                  v-if="columnItem.absoluteLink"
                  :href="columnItem.link"
                  target="_blank"
                ) {{ columnItem.label }}
                nuxt-link(
                  v-else
                  :to="columnItem.link"
                ) {{ columnItem.label }}
        .col.pb-4.footer-subscribe(v-if="$route.name !== 'newsletter'")
          .footer-heading.mb-3(data-i18n="newsletterSubscribe") {{ $t('newsletterSubscribe') }}
          NuxtLazyHydrate(:when-visible="{ rootMargin: '50px' }")
            LazyNewsletterForm(horizontal)
    .d-flex.copyright.justify-content-center.w-100.flex-column.flex-lg-row
      .mr-lg-5.align-self-center
        p(data-i18n="companyName") {{ $t('companyName') }}
      .mr-lg-5.align-self-center
        p(data-i18n="companyAddress")
          fa.mr-2(
            icon="map-marker-alt"
            width="11"
          )
          | {{ $t('companyAddress') }}
      .mr-lg-5.align-self-center
        a.py-2.text-white(
          :href="`tel:${$t('companyPhone').replace(/[- ]/g, '')}`",
          data-i18n="companyPhone"
        )
          fa.mr-2(
            icon="phone"
            width="11"
          )
          | {{ $t('companyPhone') }}
      .align-self-center
        a.py-2.text-white(
          :href="`mailto:${companyEmail}`"
          data-i18n="companyEmail"
        )
          fa.mr-2(
            icon="envelope"
            width="11"
          )
          | {{ companyEmail }}
</template>

<script setup>

const rootStore = useRootStore()
const localeStore = useLocaleStore()

const { footerLinks: footer } = storeToRefs(rootStore)
const companyEmail = computed(() => localeStore.getLocaleMessages.companyEmail)

</script>
